require.ensure([], (require) => {
    // Set jQuery as a global reference
    global.jQuery = require('jquery');
    global.$ = global.jQuery;
    
    //jQuery UI widgets
    require('jquery-ui');
    require("jquery-ui/core");
    require("jquery-ui/effect");
    require("jquery-ui/effect-shake");
    require("jquery-ui/position");
    require("jquery-ui/widget");
    require("jquery-ui/accordion");
    require("jquery-ui/button");
    require("jquery-ui/autocomplete");
    require("jquery-ui/datepicker");
    require("jquery-ui/draggable");
    require("jquery-ui/droppable");
    require("jquery-ui/menu");
    require("jquery-ui/mouse");
    require("jquery-ui/resizable");
    require("jquery-ui/selectable");
    require("jquery-ui/slider");
    require("jquery-ui/sortable");
    require("jquery-ui/spinner");
    // Load in bootstrap
    require('bootstrap');

    require('startup/styles/external-css');
    
    const _ = require('lodash');
    const Log = require('utilities/log');
    const $script = require('scriptjs');
    const auth = require('auth');
    const Polyglot = require('node-polyglot');
    const languageContext = require.context('./../../languages', true, WEBPACK_ENV.LANGUAGE_REGEX, "lazy");
    const core = require('core');
    
    // Loaded after language is pulled in
    let tokenWorkerConnector = null;
    let themeService = null;
    let versionService = null;
    let initializeTitlebar = null;
    let initBanner = null;
    let StartupTracker = null;

    class AuthenticationStartup {
        constructor() {
            this.log = new Log(module.id);
        }

        initialize() {            
            if (auth.isWorkspacePage) {
                initBanner.enableStartupDetails();
            }
            this.initTitlebarIfNeeded()
                .then(this.initTokenWorkerIfNeeded.bind(this))
                .then(versionService.fetchApplicationVersionData.bind(this))
                .then(this.loadAppCSS.bind(this))
                .then(this.loadAppJS.bind(this))
                .then(() => {
                    this.log.info(`Successfully loaded application assets`);
                })
                .catch((err) => {
                    this.log.error(`Unable to load application, failed with error: ${err}`);
                });
        }

        initTitlebarIfNeeded() {
            return new Promise((resolve, reject) => {
                const titlebarScriptUrl = _.get(auth, 'titlebarScriptUrl', false);
                if (TT.startup.titlebar) {
                    if (!titlebarScriptUrl) {
                        reject('Missing titlebar script url');
                    }
                    $script.get(titlebarScriptUrl, () => {
                        this.onTitlebarScriptLoaded();
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        }

        initTokenWorkerIfNeeded() {
            const tokenWorkerStartupTracker = new StartupTracker({
                name: 'Token Worker Initialization'
            });
            tokenWorkerStartupTracker.notifyInitializationStarting();
  
            return new Promise((resolve, reject) => {
                if (TT.startup.tokenworker) {
                    tokenWorkerConnector.initialize().then(() => {
                        tokenWorkerStartupTracker.notifyInitializationCompleted();
                        resolve();
                    }).fail((error) => {
                        tokenWorkerStartupTracker.notifyInitializationFailed({
                            message: 'Failed to initialize token worker'
                        });
                        reject();
                    }
                    );

                } else {
                    resolve();
                }
            });
        }
        

        onTitlebarScriptLoaded() {
            if (!auth.isWorkspacePage) {
                initializeTitlebar(TT.startup.customTitlebarOptions);
            }
        }

        loadAppJS() {
            const loadAppJSDeferred = new Promise((resolve, reject) => {
                // need to request startup files in a switch statement so webpack bundles them in separate bundles
                // using a variable will bundle ALL possible entry points in the same bundle creating one bing bundle
                switch (TT.startup.entryLocation) {
                case 'home':
                    require(['startup/home-startup'], resolve);
                    break;
                case 'workspace':
                    require(['startup/workspace-startup'], resolve);
                    break;
                case 'sysinfo':
                    require(['startup/sysinfo-startup'], resolve);
                    break;
                case 'acceptance':
                    require(['startup/acceptance-startup'], resolve);
                    break;
                case 'mobileChart':
                    require(['startup/chart-startup'], resolve);
                    break;
                case 'autoLaunch':
                    require(['startup/auto-launch-startup'], resolve);
                    break;
                case 'mobileChartContainer':
                    require(['mobile/chart-container-page'], resolve);
                    break;
                default:
                    reject('Could not find entry location');
                }
            });

            const applicationSourceStartupTracker = new StartupTracker({
                trackerName: 'Application Source',
                name: 'Loading Application Source'
            });
            applicationSourceStartupTracker.notifyInitializationStarting();
            loadAppJSDeferred
                .then(() => {
                    applicationSourceStartupTracker.notifyInitializationCompleted();
                })
                .catch(() => {
                    applicationSourceStartupTracker.notifyInitializationFailed();
                });
            return loadAppJSDeferred;
        }

        loadAppCSS() {
            return new Promise((resolve, reject) => {
                if (TT.startup.entryLocation === 'workspace') {
                    // ensure the theme loads before moving on
                    // this is to prevent elements like titlebar to be displayed without the css
                    themeService.initializeTheme()
                        .then(() => {
                            resolve();
                        })
                        .catch(() => {
                            reject('Failed to load theme');
                        });
                } else {
                    resolve();
                }
            });
        }
    }

    const authStartupSingleton = new AuthenticationStartup();

    // Localization
    languageContext(`./${core.currentLanguage}.json`)
        .then((content) => {
            // Set the polyglot instance on the window globally
            window.ttlang = new Polyglot(content);
            
            // Start the services
            tokenWorkerConnector = require('init!workers/token/token-worker-interface-window');
            themeService = require('init!services/theme-service');
            versionService = require('init!services/version-service');
            initializeTitlebar = require('startup/shared/initialize-titlebar');
            initBanner = require('components/initbanner/controller');
            StartupTracker = require('shared/startup-tracker');
        })
        .catch(() => authStartupSingleton.log.error(`Failed loading language: ${core.currentLanguage}`))
        .then(() => authStartupSingleton.initialize());

    return authStartupSingleton;
});

